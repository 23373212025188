<template>
  <div>
    <eden-table-actions :title="title" @search="search">
      <template slot="actions">
        <div class="ml-10 is-flex is-align-center">
          <el-button
            @click="command({ action: 'add' })"
            type="primary"
            :disabled="loading"
          >
            Create a Curation
          </el-button>
        </div>
      </template>
    </eden-table-actions>

    <eden-loader v-if="loading" />

    <template v-else>
      <el-table
        table-layout="auto"
        :data="pageData"
        v-if="pageData.length"
        :key="sort.property"
      >
        <el-table-column min-width="200">
          <template #header>
            <eden-table-column-header
              :label="'Category Name'"
              :property="'name'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'name')"
            />
          </template>
          <template v-slot="scope">
            <div class="is-flex is-align-center">
              <div class="eden-meal--image">
                <img
                  v-if="scope.row.banner_url || scope.row.banner_url === ''"
                  :src="getThumbnailFromCloudinary(scope.row.banner_url)"
                  class="meal--image"
                  alt="Image"
                  width="36px"
                  height="36px"
                />
              </div>
              <router-link
                :to="{
                  name: 'curations.details',
                  params: { id: scope.row.uuid },
                }"
              >
                <span class="font-sm text-primary">
                  {{ scope.row.name }}</span
                ></router-link
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="200">
          <template #header>
            <eden-table-column-header
              :label="'Business Type'"
              :property="'business_type'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'business_type')"
            />
          </template>
          <template v-slot="scope">
            {{ scope.row.business_types?.map((type) => type.name).join(", ") }}
          </template>
        </el-table-column>

        <el-table-column min-width="200">
          <template #header>
            <eden-table-column-header
              :label="'Start Date'"
              :property="'start_date'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'start_date')"
            />
          </template>
          <template v-slot="scope">
            <span> {{ formatDate(scope.row.start_date, "ddd do, m y") }}</span>
          </template>
        </el-table-column>

        <el-table-column min-width="200">
          <template #header>
            <eden-table-column-header
              :label="'End Date'"
              :property="'end_date'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'end_date')"
            />
          </template>
          <template v-slot="scope">
            <span> {{ formatDate(scope.row.end_date, "ddd do, m y") }}</span>
          </template>
        </el-table-column>

        <el-table-column min-width="200">
          <template #header>
            <eden-table-column-header
              :label="'Status'"
              :property="'status'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'status')"
            />
          </template>
          <template v-slot="scope">
            <span
              ><el-tag :type="setType(scope.row.status)">{{
                formatText(scope.row.status?.replace("-", " "))
              }}</el-tag></span
            >
          </template>
        </el-table-column>

        <el-table-column
          v-if="
            allowAccessFor(['superadmin', 'admin', 'vendor_manager', 'growth'])
          "
          width="80"
        >
          <template v-slot="scope">
            <el-dropdown @command="command" class="more">
              <span class="el-dropdown-link">
                <i class="eden-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  icon="eden-icon-edit"
                  :command="{
                    action: 'edit',
                    id: scope.row.uuid,
                    index: scope.$index,
                  }"
                  >Edit Curation</el-dropdown-item
                >
                <el-dropdown-item
                  icon="eden-icon-delete"
                  :command="{
                    action: 'delete',
                    id: scope.row.uuid,
                    index: scope.$index,
                  }"
                  >Delete Curation</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <eden-content-empty v-else :text="'No Curations'" />
      <eden-pagination
        v-if="pageData.length"
        :from="from"
        :to="to"
        :total="total"
        :current-page.sync="page"
      />
      <curation-delete
        :show.sync="showDeleteCuration"
        :curation-id="curationdId"
        @success="getCurations"
      />

      <curations-form
        :action="curation.action"
        :show.sync="curation.visibility"
        @success="getCurations"
        :curation="curation.data"
      />
    </template>
  </div>
</template>

<script>
import curation from "../../requests/curations/curation";
import CurationsForm from "./CurationsForm.vue";
import CurationDelete from "./CurationDelete.vue";
export default {
  components: {
    CurationsForm,
    CurationDelete,
  },

  props: {
    newCuration: Object,
    tag: {
      type: Object,
      default: () => ({}), // This will not cause a linting error
    },
  },

  data() {
    return {
      showCreateCuration: false,
      curation: {
        action: "add",
        visibility: false,
        data: {},
      },
      loading: false,
      curationdId: "",
      page: 1,
      showDeleteCuration: false,
      pageData: [],
      showPagination: true,
      sort: {
        property: "name",
        direction: "asc",
      },
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      pagination: "",
      showExportCustomers: false,
      pageDataTime: "",
      searchQuery: "",
      exportData: [],
    };
  },

  watch: {
    page() {
      this.getCurations();
    },
  },

  computed: {
    title() {
      return this.pageData.length + " Curations";
    },
    curationId() {
      return this.$route.params.id;
    },
  },

  created() {
    this.getCurations();
  },

  methods: {
    handleNewCuration(newCuration) {
      this.curations.push(newCuration);
    },
    removeCuration(id) {
      this.pageData = this.pageData.filter((curation) => curation.id !== id);
    },
    sortPageData(direction, property) {
      this.sort.property = property;
      this.sort.direction = direction;
      this.pageData = this.sortList(this.pageData, property, direction);
    },
    command(command) {
      this.curationdId = command.id;
      switch (command.action) {
        case "add":
          this.curation.data = {};
          this.curation.action = "add";
          this.curation.visibility = true;
          break;
        case "edit":
          this.curation.data = this.pageData[command.index];
          this.curation.action = "edit";
          this.curation.visibility = true;
          break;
        case "delete":
          this.curation.data = this.pageData[command.index];
          this.showDeleteCuration = true;
          break;
      }
    },
    search(query) {
      console.log(query);
      this.searchQuery = query;
      this.page = 1; // Reset page to 1 when a new search is made
      this.getCurations();
    },
    fetchCurations(params) {
      curation
        .list(params)
        .then(this.handleOrderResponse)
        .catch(this.handleError);
    },
    handleOrderResponse({ data }) {
      const { status, data: curationData } = data;
      if (status) {
        this.pageData = curationData.data;
        this.pagination = curationData;
        this.from = this.pagination.from;
        this.to = this.pagination.to;
        this.total = this.pagination.total;
        this.pageSize = this.pagination.per_page;
        this.loading = false;
      }
    },
    getCurations() {
      this.loading = true;
      let params = `business_type_id=${this.tag.id}&lite=1&page=${this.page}`;
      if (this.searchQuery) {
        params += `&search=${this.searchQuery}`;
      }
      this.fetchCurations(params);
    },

    handleError(error) {
      this.loading = false;
      const errorMessage = error.response?.data;

      if (errorMessage.errors) {
        const errorKeys = Object.keys(errorMessage.errors);
        this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
      } else {
        this.$message.error(errorMessage.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.meal--image {
  border-radius: 8px;
  margin-right: 10px;
}
</style>
